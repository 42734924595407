import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ViewContainer from './container'
import { Layout, Menu, Row, Col, Image, Button, Divider, Spin } from 'antd'
import { UploadOutlined, DashboardOutlined, UsergroupDeleteOutlined, DiffOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons'

const { Content, Footer, Sider } = Layout

const Styled = styled.div`

  .ant-layout-content {
    margin: 0 !important;
  }

  .ant-layout-sider {
    min-height: 100vh;
    flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 200px !important;
    width: 260px !important;
  }

  .site-layout-background {
    padding: 60px 60px 0px 60px !important;
    background-color: #e7f6fa;
  }
  
  .ant-layout-footer {
    padding: 24px 60px;
    background-color: #E7F7F9;
  }

  .logo-container {
    color: #ffffff;
  }
  .ant-menu-item-disabled {
    height: 15px;
  }
`

const View = ({ children, ...rest }) => (
  <ViewContainer>
    {({ me, isLoading, currentPath, handleMyProfile, handleMenu, handleLogout }) => (
      <Styled className='layout' {...rest}>
        <Spin spinning={isLoading}>
        {me && (
          <Layout>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
            >
              <Row className='logo-container mb-5' gutter={[16, 16]}>
                <Col className='center-vh pt-5' span={24}>
                  <Image src='/assets/images/adn-logo-w-text-white.png' preview={false} />
                </Col>
                <Col className='text-center' span={24}>
                  <p style={{ fontSize: '14px' }}>{me && `${me.first_name} ${me.last_name}`}</p>
                  <p style={{ color: 'var(--silver-chalice)', fontSize: '12px' }}>Distributor</p>
                </Col>
                <Col span={16} offset={4}>
                  <Button
                    onClick={handleMyProfile}
                    type='ghost'
                    block
                    size='large'
                    name='submit'
                  >
                    <span style={{ color: '#ffffff' }}>My Profile</span>
                  </Button>
                </Col>
              </Row>
              <Menu
                onClick={handleMenu}
                theme='dark'
                mode='inline'
                selectedKeys={[currentPath]}
              >
                <Menu.Item key='/' icon={<DashboardOutlined />}>
                  Dashboard
                </Menu.Item>
                <Menu.Item key='/distributors' icon={<UsergroupDeleteOutlined />}>
                  Distributors
                </Menu.Item>
                <Menu.Item key='/commissions' icon={<UploadOutlined />}>
                  Commissions
                </Menu.Item>
                <Menu.Item key='/disbursements' icon={<DiffOutlined />}>
                  Disbursements
                </Menu.Item>
                {/* <Menu.Item disabled={true} key='/disbursements-coming-soon' style={{ paddingLeft: '48px', marginBottom: '20px' }}>
                  <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item> */}
              </Menu>
              <Row className='mt-1'>
                <Col span={20} offset={2}>
                  <Divider style={{ borderColor: '#011420' }} />
                </Col>
              </Row>
              <Menu
                theme='dark'
                mode='inline'
                selectedKeys={[currentPath]}
              >
                <Menu.Item onClick={handleMenu} key='/account-settings' icon={<UserOutlined />}>
                  Account Settings
                </Menu.Item>
                <Menu.Item onClick={handleLogout} key='logout' icon={<LogoutOutlined />}>
                  Log out
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                  {children({})}
                </div>
              </Content>
              <Footer>
                <Row>
                  <Col span={24}>
                    <Divider style={{ borderColor: '#E0E0E0' }} />
                  </Col>
                </Row>
                <Row>
                  <Col className='center-left-vh' span={12}>
                    <span style={{ color: '#A9A9A9' }}>© 2021 AllCare. All rights reserved.</span>
                  </Col>
                  <Col className='center-right-vh' span={12}>
                    <p style={{ color: '#14B2BF' }}>
                      Terms and Conditions  <span style={{ color: '#E0E0E0', fontSize: '20px' }}>|</span>  Privacy Policy
                    </p>
                  </Col>
                </Row>
              </Footer>
            </Layout>
          </Layout>
        )}
        </Spin>
      </Styled>
    )}
  </ViewContainer >
)

View.propTypes = {
  children: PropTypes.any
}
export default View
