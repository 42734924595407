import { createStore } from 'easy-peasy'
// Login
import login from './login'

// Logout
import logout from './logout'

// Me
import me from './me'

// Commissions
import commissions from './commissions'

// Downlines
import downlines from './downlines'

// Payments
import payments from './payments'

// Banks
import banks from './banks'

// Cities
import cities from './cities'

// Provinces
import provinces from './provinces'

// Images
import images from './images'

// Government ID Types
import governmentIdTypes from './government_id_types'

// Signup
import signup from './signup'

// Forgot Password Request
import forgotPasswordRequest from './forgot_password_request'

// Change Password
import changePassword from './change_password'

// Update Password
import updatePassword from './update_password'

// Terms and Conditions
import termsAndConditions from './terms_and_conditions'

// Plans
import plans from './plans'

// Disbursement
import disbursement from './disbursement'

export default createStore({
  // Login
  ...login,
  // Logout
  ...logout,
  // Me
  ...me,
  // Commissions
  ...commissions,
  // Downlines
  ...downlines,
  // Payments
  ...payments,
  // Banks
  ...banks,
  // Cities
  ...cities,
  // Provinces
  ...provinces,
  // Images
  ...images,
  // Government ID Types
  ...governmentIdTypes,
  // Signup
  ...signup,
  // Forgot Password Request
  ...forgotPasswordRequest,
  // Change Password
  ...changePassword,
  // Update Password
  ...updatePassword,
  // Terms and conditions
  ...termsAndConditions,
  // Plans
  ...plans,
  // Disbursement
  ...disbursement
})
