import { action, thunk } from 'easy-peasy'
import { API_URL } from '../../utils/constants'
import axios from 'axios'

export default {
  // Get Disbursements
  disbursements: { status: 0, payload: null },
  setDisbursements: action((state, payload = null) => {
    state.disbursements = payload
  }),
  getDisbursements: thunk(async (actions, params = {}) => {
    actions.setDisbursements({ status: 1, payload: null })
    axios.get(`${API_URL}/api/v1/disbursements`, { params })
      .then(response => actions.setDisbursements({ status: 2, payload: response.data }))
      .catch(e => actions.setDisbursements({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Request for payout
  disbursement: { status: 0, payload: null },
  setDisbursement: action((state, payload = null) => {
    state.disbursement = payload
  }),
  postDisbursement: thunk(async (actions, data = {}) => {
    actions.setDisbursement({ status: 1, payload: null })
    axios.post(`${API_URL}/api/v1/disbursements`, data)
      .then(response => actions.setDisbursement({ status: 2, payload: response.data }))
      .catch(e => actions.setDisbursement({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
