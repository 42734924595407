import { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'

const Container = ({ children }) => {
  // State
  const state = useStoreState(s => ({
    me: s.me,
    logout: s.logout
  }))

  // Actions
  const actions = useStoreActions(a => ({
    getMe: a.getMe,
    postLogout: a.postLogout,
    setLogout: a.setLogout
  }))

  const location = useLocation()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const [me, setMe] = useState(null)
  const [currentPath, setCurrentPath] = useState('dashboard')

  const handleMyProfile = () => {
    history.push('/my-profile')
  }

  const handleMenu = ({ key }) => {
    history.push(key)
  }

  const handleLogout = () => {
    setIsLoading(true)
    if (sessionStorage.getItem('oauth')) {
      const oauth = JSON.parse(sessionStorage.getItem('oauth'))
      actions.postLogout({
        token: oauth.access_token,
        client_id: process.env.REACT_APP_SECRET_ID,
        client_secret: process.env.REACT_APP_SECRET_KEY
      })
    }
  }

  useEffect(() => {
    actions.getMe()
    setIsLoading(true)
    if (location.pathname) {
      setCurrentPath(location.pathname)
    }
  }, [])

  useEffect(() => {
    const { status, payload } = state.me
    const forSignupStatuses = ['PENDING_VERIFICATION', 'PENDING_PAYMENT']

    if (status === 2 && payload) {
      if (forSignupStatuses.includes(payload.status)) {
        message.warning('Pleae complete your signup process')
        history.push('/signup')
      }
      setMe(payload)
      setIsLoading(false)
    } else if (status === 3) {
      setIsLoading(false)
    }
  }, [state.me])

  useEffect(() => {
    const { status } = state.logout
    if (status === 2) {
      actions.setLogout({ status: 0, payload: null })
      sessionStorage.removeItem('oauth')
      history.go(0)
    } else if (status === 3) {
      actions.setLogout({ status: 0, payload: null })
    }
  }, [state.logout])

  return children({ me, isLoading, currentPath, handleMyProfile, handleMenu, handleLogout })
}

export default Container
