import React, { Suspense, lazy } from 'react'
import Loader from './components/Loader'
import PrivateRoute from './components/PrivateRoute'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import DefaultLayout from './components/layout/DefaultLayout'
import DashboardLayout from './components/layout/DashboardLayout'

// Sample Component Product
const MyProfilePage = lazy(() => import('./components/MyProfilePage'))
const DashboardPage = lazy(() => import('./components/DashboardPage'))
const DistributorsPage = lazy(() => import('./components/DistributorsPage'))
const DisbursementsPage = lazy(() => import('./components/DisbursementsPage'))
const CommissionsPage = lazy(() => import('./components/CommissionsPage'))
const AccountSettingsPage = lazy(() => import('./components/AccountSettingsPage'))
const InviteAuth = lazy(() => import('./components/Signup/InviteAuth'))
const Signup = lazy(() => import('./components/Signup/Main'))
const PaymentSuccess = lazy(() => import('./components/Signup/PaymentSuccess'))
const PaymentFailure = lazy(() => import('./components/Signup/PaymentFailure'))
const CreatePassword = lazy(() => import('./components/CreatePassword/Main'))
const CreatePasswordSuccess = lazy(() => import('./components/CreatePassword/Success'))
const ForgotPasswordPage = lazy(() => import('./components/ForgotPasswordPage'))
const CheckEmailPage = lazy(() => import('./components/CheckEmailPage'))
const CreateNewPasswordPage = lazy(() => import('./components/CreateNewPasswordPage/Main'))
const CreateNewPasswordPageSuccess = lazy(() => import('./components/CreateNewPasswordPage/Success'))
const EmailTemplate = lazy(() => import('./components/EmailTemplate'))

const LoginPage = lazy(() => import('./components/LoginPage'))

const isLoggedIn = sessionStorage.getItem('oauth')

const Routes = () => (
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Switch>
        {/* <Route exact path='/login' component={LoginPage} /> */}
        <Route exact path='/' component={isLoggedIn ? DashboardPage : LoginPage } layout={isLoggedIn ? DashboardLayout : DefaultLayout } />
        <Route exact path='/forgot-password' component={ForgotPasswordPage} layout={DefaultLayout} />
        <Route exact path='/new-password' component={CreateNewPasswordPage} layout={DefaultLayout} />
        <Route exact path='/new-password/success' component={CreateNewPasswordPageSuccess} layout={DefaultLayout} />

        <Route exact path='/invite-auth/:code' component={InviteAuth} layout={DefaultLayout} />
        <PrivateRoute>
          {() => (
            <Switch>
              <Route exact path='/my-profile' component={MyProfilePage} />
              <Route exact path='/distributors' component={DistributorsPage} layout={DashboardLayout} />
              <Route exact path='/disbursements' component={DisbursementsPage} layout={DashboardLayout} />
              <Route exact path='/commissions' component={CommissionsPage} layout={DashboardLayout} />
              <Route exact path='/account-settings' component={AccountSettingsPage} layout={DashboardLayout} />
              <Route exact path='/signup' component={Signup} layout={DefaultLayout} />
              <Route exact path='/signup/payment-success' component={PaymentSuccess} layout={DefaultLayout} />
              <Route exact path='/signup/payment-failure' component={PaymentFailure} layout={DefaultLayout} />
              <Route exact path='/create-password' component={CreatePassword} layout={DefaultLayout} />
              <Route exact path='/create-password/success' component={CreatePasswordSuccess} layout={DefaultLayout} />
              <Route exact path='/check-email' component={CheckEmailPage} layout={DefaultLayout} />
              <Route exact path='/email-template' component={EmailTemplate} layout={DefaultLayout} />
            </Switch>
          )}
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  </Suspense>
)

export default Routes
