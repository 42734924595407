import { action, thunk } from 'easy-peasy'
import { API_URL } from '../../utils/constants'
import axios from 'axios'

export default {
  downlines: { status: 0, payload: null },
  setDownlines: action((state, payload = null) => {
    state.downlines = payload
  }),
  getDownlines: thunk(async (actions, params = {}) => {
    actions.setDownlines({ status: 1, payload: null })
    axios.get(`${API_URL}/api/v1/downlines/`, { params })
      .then(response => actions.setDownlines({ status: 2, payload: response.data }))
      .catch(e => actions.setDownlines({
        status: 3,
        payload: typeof e.response !== 'undefined' ? e.response.data : e
      }))
  }),
  // Create Downline
  createdDownline: { status: 0, payload: null },
  setCreatedDownline: action((state, payload = null) => {
    state.createdDownline = payload
  }),
  createDownline: thunk(async (actions, data = {}) => {
    actions.setCreatedDownline({ status: 1, payload: null })
    axios.post(`${API_URL}/api/v1/downlines/`, data)
      .then(response => actions.setCreatedDownline({ status: 2, payload: response.data }))
      .catch(e => actions.setCreatedDownline({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
