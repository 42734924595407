import { action, thunk } from 'easy-peasy'
import { API_URL } from '../../utils/constants'
import axios from 'axios'

// git = Government ID Types
export default {
  git: { status: 0, payload: null },
  setGit: action((state, payload = null) => {
    state.git = payload
  }),
  getGit: thunk(async (actions, params = {}) => {
    actions.setGit({ status: 1, payload: null })
    axios.get(`${API_URL}/api/v1/government_id_types`, { params })
      .then(response => actions.setGit({ status: 2, payload: response.data }))
      .catch(e => actions.setGit({
        status: 3,
        payload: typeof e.response !== 'undefined' ? e.response.data : e
      }))
  })
}
